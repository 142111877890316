<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" :model="filters">
				<span>
					<!--  label="方案号" -->
					<el-form-item>
						<el-input v-model="filters.order_num" placeholder="方案号" clearable class="w120"></el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="filters.store_id" placeholder="店铺ID" clearable class="w160"></el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="filters.mobile" placeholder="用户手机号" clearable class="w160"></el-input>
					</el-form-item>
					<!-- label="方案状态" -->
					<el-form-item>
						<el-select v-model="filters.status" placeholder="方案状态" clearable class="w180">
							<el-option v-for="item in planStart" :key="item.label" :label="item.value" :value="item.label"></el-option>
						</el-select>
					</el-form-item>
					<!-- label="时间" -->
					<el-form-item>
						<el-date-picker v-model="time" type="daterange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd" ></el-date-picker>
					</el-form-item>
					<!-- label="彩种" -->
					<el-form-item >
						<el-select v-model="filters.lottery_type" placeholder="选择彩种" clearable clearable class="w120">
							<el-option v-for="item in lotteryType" :key="item.label" :label="item.value" :value="item.label"></el-option>
						</el-select>
					</el-form-item>		
					<el-form-item >
						<el-select v-model="filters.user_require_ticket_img" placeholder="选择查票的状态" clearable clearable class="w120">
							<el-option v-for="item in checkTkgStatus" :key="item.label" :label="item.value" :value="item.label"></el-option>
						</el-select>
					</el-form-item>	


					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">查询</el-button>
					</el-form-item>
				</span>
			</el-form>
		</el-col>

		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="order_num" label="方案号" sortable width="100" align="center">
			</el-table-column>
			<!-- <el-table-column prop="qishu" label="方案期数" width="80">
			</el-table-column> -->
			<el-table-column prop="status_str" label="方案状态" width="80" align="center">
			</el-table-column>
			<el-table-column prop="createtime" label="时间" width="150">
			</el-table-column>
			<el-table-column prop="awardtime" label="兑奖时间" width="150">
			</el-table-column>
			<el-table-column prop="pay_type" label="支付方式" width="90" align="center">
			</el-table-column>
			<el-table-column prop="money" label="金额/票数" width="100" align="center">
				<template slot-scope="scope">
					{{scope.row.money}} / {{scope.row.ticket}}
				</template>
			</el-table-column>
			<el-table-column prop="prized_money" label="中奖奖金" width="90" align="center">
			</el-table-column>
			<el-table-column prop="lottery_name" label="彩票种类" width="90" align="center">
			</el-table-column>
			<el-table-column prop="store_id" label="店铺ID" width="80" align="center">
			</el-table-column>
			<el-table-column prop="store_name" label="店铺名称" width="150">
			</el-table-column>
			<el-table-column prop="" label="转单" width="60" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.is_transfer==2">是</span><span v-else>否</span>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="mobile" label="出票方" width="60" align="center">
				<template slot-scope="scope">
					{{scope.row.is_issue_ticket ? '是':'否'}}
				</template>
			</el-table-column> -->
			<!--  -->
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="primary" plain size="small" @click="seeinfo(scope.row.id,scope.row.user_id,scope.row.lottery_type,scope.row.store_id)">详情</el-button>
					<!--
					<el-button v-if="scope.row.choucha" plain size="small">抽</el-button>
					<el-button v-if="!scope.row.user_require_ticket_img && !scope.row.tk_img&&!scope.row.choucha"  type="primary" plain size="small" @click="setChuanpiao(scope.row.order_num)">抽票</el-button>
					-->
					<el-button v-if="scope.row.status == 60 || scope.row.status == 70" type="primary" plain size="small" @click="getReopen(scope.row)">重新开奖</el-button>

					<el-button v-if="scope.row.user_require_ticket_img > 0" type="primary" plain size="small" @click="noCheckTicket(scope.row)">不抽查</el-button>
				</template>
			</el-table-column>
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar flex-bet">
			<!-- <el-button type="success">导出列表</el-button> -->
			<div><span v-if="money">总金额：{{money}}</span></div>
			<!--分页-->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-col>
		<el-dialog title="订单信息" :visible.sync="dialogShow" width="500px" center>
			<el-form :model="storeInfo" label-width="90px">
				<el-form-item label="付款金额">
					<el-input v-model="storeInfo.store_name" placeholder="输入店名" @input="" class="w300"></el-input>
				</el-form-item>
				<el-form-item label="订单内容">
					<el-input v-model="storeInfo.domain" placeholder="输入域名" class="w300"></el-input>月
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="storeInfo.domain" placeholder="20个字符" class="w300"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="">确 认</el-button>
				<el-button @click="dialogShow = false">取 消</el-button>
			</span>
		</el-dialog>
	</section>
</template>

<script>
	let _t = undefined
	import { planlist,reopen,setPass,setChoucha } from '../../api/api';	
	import common from '@/utils/common';
	import { getTime } from '../../../static/help';
	export default {
		data() {
			return {
				money: '',				
				time:[],
				searchType:[
					{
						value:'店铺ID',
						label: 'store_id'
					},{
						value:'店铺域名',
						label: 'store_host'
					},{
						value:'店主手机号',
						label: 'store_mobile'
					}
				],
				planStart:[
					{
						value:'下单保存方案',
						label: '10',
					},{
						value:'方案提交后台待出票',
						label: '20',
					},{
						value:'方案已被店铺接单',
						label: '30',
					},{
						value:'出票完成（结账）',
						label: '50',
					},{
						value:'已中奖',
						label: '60',
					},{
						value:'未中奖',
						label: '70',
					},{
						value:'出票失败',
						label: '80',
					},{
						value:'已兑奖',
						label: '90',
					},{
						value:'已中拒兑',
						label: '100',
					},{
						value:'已撤单',
						label: '-10',
					},{
						value:'被店主拒单',
						label: '-20',
					},{
						value:'过点撤单',
						label: '-40',
					}
				],
				lotteryType:[
					{
						value:'竞彩足球',
						label: '1'
					},{
						value:'北京单场',
						label: '2'
					},{
						value:'足彩',
						label: '3'
					},{
						value:'竞彩篮球',
						label: '5'
					},{
						value:'数字彩',
						label: '6'
					}
				],

				checkTkgStatus:[
					{
						value:'用户要求上传票样',
						label: '1'
					},{
						value:'结账后随机抽查',
						label: '2'
					},{
						value:'竞彩截止出票随机抽查',
						label: '3'
					},{
						value:'票样程序检查不合格',
						label: '99'
					}
				],

				dialogShow: false,
				filters: {
					order_num: '',
					start_time: '',
					end_time: '',
					status: '',
					lottery_type: '',
					store_id: '',
					mobile: ''
				},
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 20, //每页条数
				pagesizes: [10, 20, 50, 100], //可选条数
				listLoading: false,
				storeInfo: {
					amount: '',
					month: '',
					remark: ''
				},
			}
		},
		created() {
			_t = this
			_t.time = [common.getDay(-7), common.dateFormater('YYYY-MM-DD')];
			_t.filters.start_time = _t.time[0]
			_t.filters.end_time = _t.time[1]
			//获取列表
			// this.getList(1);
		},
		watch:{
			'time': function(newValue,oldVale){
				if(!newValue){
					this.filters.start_time = ''
					this.filters.end_time = ''
				}else{
					this.filters.start_time = this.time[0]
					this.filters.end_time = this.time[1]
				}
			},
		},
		methods: {
			setChuanpiao(id){
				setChoucha({plan_no:id}).then((res) => {
					if(res.code == 1) {
						this.$message.success('设置成功！')
						this.getList();
					}
				})
			},
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取用户列表
			getList(val) {
				if(val == '0') this.page = 1
				var flag = !0, f = this.filters;
				for(var key in f){
					if(f[key]){
						flag = !1
					}
				}
				if(flag) return this.$message.error('筛选条件不能为空！')
				// if(!f.order_num && f.lottery_type) return this.$message.error('请选择相关店铺进行关联搜索')
				let para = {
					...f,
					page: this.page,
					pagesize: this.pagesize //分页条数
				};
				this.listLoading = true;
				planlist(para).then((res) => {
					this.listLoading = false;
					if(res.code == 1) {
						var data = res.data.list;
						data.forEach(item =>{
							item.createtime = getTime(item.createtime)
							item.awardtime = getTime(item.awardtime)
							// item.order_confirm_at = getTime(item.order_confirm_at)						
						})
						this.money = res.data.money;
						this.total = res.data.count;
						this.listData = data;
						this.listLoading = false;
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			getReopen: function(obj){
				let para = {
					order_id: obj.order_id || obj.order_num,
					lottery_type: obj.lottery_type
				};
				reopen(para).then((res) => {
					if(res.code == 1) this.getList();
					else this.$notify.error('错误');
				});
			},

			noCheckTicket: function(obj){
				let para = {
					order_id: obj.order_id || obj.order_num,
					lottery_type: obj.lottery_type,
					store_id: obj.store_id,
				};
				setPass(para).then((res) => {
					if(res.code == 1) this.getList();
					else this.$notify.error('错误');
				});
			},

			seeinfo:function(id,user_id,lottery_type,store_id){
				// this.$router.push({ name: '方案详情', query: { order_id: id,user_id: user_id,lottery_type: lottery_type,store_id: store_id }})
				const routeUrl = this.$router.resolve({
					path: "/operate/plan/planDetail",
					query: {
						order_id: id,
						user_id: user_id,
						lottery_type: lottery_type,
						store_id: store_id
					}
				});
				window.open(routeUrl.href, '_blank');
			},
		},
		mounted() { 				
			//获取列表数据
			// this.getList();
		}
	}
</script>

<style lang="scss" scoped>
@import "../../styles/permission.scss";
	.w120{ width: 120px }
	.w160{ width: 160px }
	.w180{ width: 180px }
</style>